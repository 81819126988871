import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  IDL,
  MerkleDistributor,
} from '@/types/merkle_distributor';
import { BN } from '@coral-xyz/anchor';
import NodeWallet from '@coral-xyz/anchor/dist/cjs/nodewallet';
import {
  AnchorProvider,
  Program,
} from '@project-serum/anchor';
import {
  Context,
  DistributorClient,
  PDA,
  PDAInfo,
} from '@renec-foundation/distributor-sdk';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  Connection,
  Keypair,
  PublicKey,
} from '@solana/web3.js';

const defaultSolanaContext = {
  connection: null as Connection | null,
  provider: null as AnchorProvider | null,
  program: null as Program<MerkleDistributor> | null,
  ctx: null as Context | null,
  distributorClient: null as DistributorClient | null,
  mintPublicKey: new PublicKey('11111111111111111111111111111111'),
  merkleTreeVersion: 1,
  distributor: null as PDAInfo | null,
  publicKey: null as PublicKey | null,
  connected: false,
  connect: async () => {},
  disconnect: async () => {},
};

const SolanaContext = React.createContext(defaultSolanaContext);

interface SolanaProviderProps {
  children: ReactNode;
}
export const SolanaProvider: React.FC<SolanaProviderProps> = ({ children }) => {
  const {
    connect,
    disconnect,
    connected,
    publicKey: userPublicKey,
    signTransaction,
    signAllTransactions,
  } = useWallet();
  const defaultKeypair = Keypair.generate();
  const defaultWallet = new NodeWallet(defaultKeypair);
  const [publicKey, setPublicKey] = useState<PublicKey>(
    defaultKeypair.publicKey,
  );
  const connectionUrl = process.env.NEXT_PUBLIC_SOLANA_CONNECTION_URL;
  if (!connectionUrl) {
    throw new Error('NEXT_PUBLIC_SOLANA_CONNECTION_URL is not defined');
  }
  const connection = new Connection(connectionUrl, 'confirmed');
  const [provider, setProvider] = useState<AnchorProvider>(
    new AnchorProvider(connection, defaultWallet, {}),
  );

  const programAddress = process.env.NEXT_PUBLIC_PROGRAM_ADDRESS;
  if (!programAddress) {
    throw new Error('NEXT_PUBLIC_PROGRAM_ADDRESS is not defined');
  }
  const programId = new PublicKey(programAddress);
  const program = new Program<MerkleDistributor>(IDL, programId, provider);

  const ctx = Context.withProvider(provider, new PublicKey(program.programId));

  const distributorClient = new DistributorClient(
    ctx,
    new PDA(program.programId),
  );

  const mintAddress = process.env.NEXT_PUBLIC_MINT_ADDRESS; // TODO move to useEffect if get mintAddress from database
  if (!mintAddress) {
    throw new Error('NEXT_PUBLIC_MINT_ADDRESS is not defined');
  }
  const mintPublicKey = new PublicKey(mintAddress);

  const merkleTreeVersion = parseInt(
    process.env.NEXT_PUBLIC_MERKLE_TREE_VERSION ?? '1',
    10,
  );

  const distributor = distributorClient.pda.distributor(
    mintPublicKey,
    new BN(merkleTreeVersion),
  );

  useEffect(() => {
    if (connected && userPublicKey) {
      setPublicKey(userPublicKey);
      setProvider(
        new AnchorProvider(
          connection,
          {
            publicKey: userPublicKey,
            signTransaction: signTransaction!,
            signAllTransactions: signAllTransactions!,
          },
          {},
        ),
      );
    } else {
      setPublicKey(defaultKeypair.publicKey);
      setProvider(new AnchorProvider(connection, defaultWallet, {}));
    }
  }, [connected, userPublicKey]);

  const handleConnect = async () => {
    await connect();
  };

  const handleDisconnect = async () => {
    await disconnect();
  };

  const value = {
    connection,
    provider,
    program,
    ctx,
    distributorClient,
    mintPublicKey,
    merkleTreeVersion,
    distributor,
    publicKey,
    connected,
    connect: handleConnect,
    disconnect: handleDisconnect,
  };

  return (
    <SolanaContext.Provider value={value}>{children}</SolanaContext.Provider>
  );
};

export const useSolana = () => useContext(SolanaContext);
