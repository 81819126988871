import '@fontsource-variable/inter';
import '@fontsource/rubik-mono-one';
import '@/root/src/styles/global.scss';

import { useMemo } from 'react';

import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import Head from 'next/head';
import PropTypes from 'prop-types';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { SolanaProvider } from '@/providers/solana';
import ThemeProvider from '@/providers/theme';
import WalletProvider from '@/providers/wallet';
import { createEmotionCache } from '@/utils/create-emotion-cache';
import {
  CacheProvider,
  EmotionCache,
} from '@emotion/react';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <AnimatePresence mode="wait">
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Airdrop</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
          />
        </Head>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider>
              <WalletProvider>
                <SolanaProvider>
                  <Component {...pageProps} />
                </SolanaProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </WalletProvider>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </CacheProvider>
    </AnimatePresence>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
