import type { PaletteMode, ThemeOptions } from '@mui/material';

const breakpoints = {
  values: {
    xs: 0,
    sm: 640,
    md: 810,
    lg: 1200,
    xl: 1500,
  },
};

const fontFamily = 'Inter Variable';
const typography = {
  fontFamily: fontFamily,
  h1: {
    fontFamily: fontFamily,
  },
  h2: {
    fontFamily: fontFamily,
  },
  h3: {
    fontFamily: fontFamily,
  },
  h4: {
    fontFamily: fontFamily,
  },
  h5: {
    fontFamily: fontFamily,
  },
  h6: {
    fontFamily: fontFamily,
    fontWeight: 700,
  },
};

const settings = {
  light: {
    palette: {
      mode: 'light',
      background: {
        default: '#FFFFFF',
      },
      primary: {
        main: '#00782A',
      },
      secondary: {
        main: '#F5A302',
      },
      text: {
        primary: '#000000',
        secondary: '#F5A302',
      },
    },
    typography,
    components: {},
    breakpoints,
  },
};

const getThemeOptions = (mode: PaletteMode) =>
  settings['light'] as ThemeOptions;

export default getThemeOptions;
